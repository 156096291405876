export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Lexus',
  hostName: 'insight-staging.amazingdigitalexperiences.com',
  environmentName: 'staging',
  appName: 'Lexus Analytics',
  applicationInsights: {
    instrumentationKey: 'b612c5ae-49be-4245-9f4a-d8c5d41bd852'
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  showLocaleSelector: 'false',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1],
  closeRateLookbackMonths: 3,
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#000', '#666666', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  baseApiUri: 'https://lexus-analytics-api-staging-v2.azurewebsites.net/api',
  authUri: 'https://lexus-analytics-api-staging-v2.azurewebsites.net/token',
  baseV5ApiUri: 'https://lexusus-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsBaseUri: '',
  retailerToolsIncludeAuth: false,
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/lexus/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: ['InventoryOverview']
};
